<template lang="pug">
.player(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  .player_info.py-5.py-sm-10
    v-container.player_info_container.d-flex
      .player.d-flex
        .player_img
          img.temp_logo_hidden(
            v-if="playerData.info.photo",
            :src="playerData.info.photo.image"
          )
        .player_name
          h5 {{ playerData.info.name }}
          span.text-subtitle-1.text_light_gray {{ '#' + playerData.info.player_jersey_number }} | {{ playerData.info.position }}
      .board_container
        .board_row.d-flex
          .board_item.text_white.bg_primary.flex-grow-1
            span {{ playerData.info.team_name }}
          .board_item.text_white.bg_primary.flex-grow-1
            span 身高
            span(v-if="playerData.info.height") {{ playerData.info.height }}CM
          .board_item.text_white.bg_primary.flex-grow-1
            span 體重
            span(v-if="playerData.info.weight") {{ playerData.info.weight }}KG
        .board_row.d-flex
          .board_item.text_white.bg_primary.flex-grow-1
            span # {{ playerData.info.player_jersey_number }} {{ playerData.info.position }}
          .board_item.text_white.bg_primary.flex-grow-1
            span 生日
            span {{ playerData.info.birthday }}
          .board_item.text_white.bg_primary.flex-grow-1
            span 年齡
            span {{ playerData.info.age }}
        .board_row.d-flex
          .board_box
            span 平均得分
            span {{ playerData.box.avg_pts.toFixed(1) }}
          .board_box
            span 平均籃板
            span {{ playerData.box.avg_reb.toFixed(1) }}
          .board_box
            span 平均助攻
            span {{ playerData.box.avg_ast.toFixed(1) }}
  v-container.tab_container(style="padding: 0", fluid)
    v-tabs(
      show-arrows,
      background-color="transparent",
      ripple="false",
      v-model="pageIndex",
      centered
    )
      v-tabs-slider(color="primary")

      v-tab(
        @click="toPage(index)",
        :ripple="false",
        v-for="(item, index) in tabList",
        :key="index"
      ) {{ item }}
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(
            name="selectSeasonIndex",
            v-model="selectSeasonIndex"
          )
            option(:value="index", v-for="(item, index) in playerSeasons") {{ item.name }}
  v-main
    transition(name="page", mode="out-in")
      router-view
  custom-footer
</template>

<script>
import CustomFooter from "@/components/common/Footer";
import { getPlayerSeasonList, getPlayerSeasonData } from "@/api/player";
import { getObjIdxById } from "@/utils/util";

export default {
  name: "LeaguePlayer",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    CustomFooter,
  },
  data() {
    return {
      leagueID: this.$route.params.league,
      seasonID: this.$route.params.season,
      playerID: this.$route.params.id,
      playerSeasons: [],
      playerData: {
        info: {},
        box: {
          avg_pts: 0,
          avg_reb: 0,
          avg_ast: 0,
        },
      },
      selectSeasonIndex: -1,
      pageIndex: -1,
      // tabList: ["比賽紀錄", "數據", "生涯", "進階", "團隊效率"],
      tabList: ["比賽紀錄", "數據"],
      //TODO: siteTitle
      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        default_title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    async selectSeasonIndex() {
      if (this.selectSeasonIndex != -1) {
        await this.getPlayerSeasonData();
        this.toPage(this.pageIndex);
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.setPage(this.$route.name);
      await this.getPlayerSeasonList();
      await this.getPlayerSeasonData();
    },
    async getPlayerSeasonList() {
      let temp = {
        league_id: this.leagueID,
        player_id: this.playerID,
      };
      await getPlayerSeasonList(temp).then((response) => {
        this.playerSeasons = response.data;

        var index = getObjIdxById(this.seasonID, response.data);
        if (index != -1) {
          this.selectSeasonIndex = index;
        } else {
          this.selectSeasonIndex = 0;
        }
      });
    },
    async getPlayerSeasonData() {
      let temp = {
        season_id:
          this.playerSeasons.length > 0
            ? this.playerSeasons[this.selectSeasonIndex].id
            : null,
        player_id: this.playerID,
        part: "info,box",
      };

      await getPlayerSeasonData(temp).then((response) => {
        this.playerData =
          response.data.summary[response.data.summary.length - 1];
        this.metaData.title =
          this.playerData.info.name + " | " + this.metaData.default_title;
      });
    },
    setPage(name) {
      switch (name) {
        case "LeaguePlayerGameLog":
          this.pageIndex = 0;
          break;
        case "LeaguePlayerSeason":
          this.pageIndex = 1;
          break;
        case "LeaguePlayerJob":
          this.pageIndex = 2;
          break;
        case "LeaguePlayerAdvanced":
          this.pageIndex = 3;
          break;
        case "LeaguePlayerTeamEffect":
          this.pageIndex = 4;
          break;
      }
    },
    toPage(index) {
      this.pageIndex = index;
      switch (index) {
        case 0:
          this.$router.push({
            name: "LeaguePlayerGameLog",
            params: {
              season: this.playerSeasons[this.selectSeasonIndex].id,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "LeaguePlayerSeason",
            params: {
              season: this.playerSeasons[this.selectSeasonIndex].id,
            },
          });
          break;
        case 2:
          this.$router.push({ name: "LeaguePlayerJob" });
          break;
        case 3:
          this.$router.push({ name: "LeaguePlayerAdvanced" });
          break;
        case 4:
          this.$router.push({ name: "LeaguePlayerTeamEffect" });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/league/player";
</style>
